import * as React from "react";

function SvgComponent(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        viewBox="0 0 48 48"
      >
        <g fill="none" stroke="#000" strokeLinejoin="round" strokeWidth="3">
          <path
            strokeLinecap="round"
            d="M22 9H11C9.34315 9 8 10.3431 8 12V33H40V22"
          />
          <path
            fill="#f40051"
            d="M4 33H44V35C44 38.3137 41.3137 41 38 41H10C6.68629 41 4 38.3137 4 35V33Z"
          />
          <path strokeLinecap="round" d="M33 7L29 11L33 15" />
          <path strokeLinecap="round" d="M39 7L43 11L39 15" />
        </g>
      </svg>
    </>
  );
}

export default SvgComponent;
