import React from "react";
import styled from "styled-components";

const WhatsAppButton = ({
  title = "Contáctanos",
  phoneNumber = "+51960604334",
  message = "Hola, estoy interesado en sus servicios.",
  border,
}) => {
  const handleContactClick = () => {
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  return (
    <Wrapper
      className="animate pointer radius8"
      onClick={handleContactClick}
      border={border}
    >
      {title}
    </Wrapper>
  );
};

const Wrapper = styled.button`
  border: 1px solid ${(props) => (props.border ? "#707070" : "#7620ff")};
  background-color: ${(props) => (props.border ? "transparent" : "#7620ff")};
  width: 100%;
  padding: 15px;
  outline: none;
  color: ${(props) => (props.border ? "#707070" : "#fff")};
  :hover {
    background-color: ${(props) => (props.border ? "transparent" : "#580cd2")};
    border: 1px solid #7620ff;
    color: ${(props) => (props.border ? "#7620ff" : "#fff")};
  }
`;

export default WhatsAppButton;
