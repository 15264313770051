import * as React from "react";

function SvgComponent(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        viewBox="0 0 48 48"
      >
        <g fill="none">
          <path
            stroke="#000"
            strokeWidth="3"
            d="M18 10H42C43.1046 10 44 10.8954 44 12V38C44 39.1046 43.1046 40 42 40H18"
          />
          <path
            fill="#f2b300"
            stroke="#000"
            strokeWidth="3"
            d="M4 12C4 10.8954 4.89543 10 6 10H18V40H6C4.89543 40 4 39.1046 4 38V12Z"
          />
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            d="M11 17V21"
          />
          <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
            d="M37 16L25 33"
          />
          <rect width="4" height="4" x="9" y="25" fill="#fff" rx="2" />
          <rect width="4" height="4" x="9" y="31" fill="#fff" rx="2" />
        </g>
      </svg>
    </>
  );
}

export default SvgComponent;
