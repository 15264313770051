import * as React from "react";

function SvgComponent(props) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        viewBox="0 0 48 48"
      >
        <g fill="none" stroke="#000" strokeWidth="3">
          <circle
            cx="24"
            cy="11"
            r="7"
            fill="#7620ff"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4 41C4 32.1634 12.0589 25 22 25"
          />
          <circle cx="35" cy="34" r="6" fill="#7620ff" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M40 38L44 41" />
        </g>
      </svg>
    </>
  );
}

export default SvgComponent;
